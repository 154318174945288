import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getMovieTrailer, getMovieDetails, getMovieCredits, getMovieRecommendations, getMovieReviews } from '../api/tmdb';
import { auth, database } from '../firebase/config';
import { ref, set, onValue, remove } from 'firebase/database';
import './MovieTrailer.css';

function MovieTrailer() {
  const [trailer, setTrailer] = useState(null);
  const [movieDetails, setMovieDetails] = useState(null);
  const [credits, setCredits] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const recommendationsRef = useRef(null);
  const SCROLL_AMOUNT = 1000;
  const { movieId } = useParams();
  const navigate = useNavigate();
  const [canScrollLeftCast, setCanScrollLeftCast] = useState(false);
  const [canScrollRightCast, setCanScrollRightCast] = useState(true);
  const [isScrollingCast, setIsScrollingCast] = useState(false);
  const castListRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [user] = useState(auth.currentUser);

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
    
    const fetchData = async () => {
      try {
        const [trailerRes, detailsRes, creditsRes, recommendationsRes, reviewsRes] = await Promise.all([
          getMovieTrailer(movieId),
          getMovieDetails(movieId),
          getMovieCredits(movieId),
          getMovieRecommendations(movieId, 1),
          getMovieReviews(movieId)
        ]);

        const trailerData = trailerRes.data.results.find(
          (video) => video.type === 'Trailer'
        );
        setTrailer(trailerData);
        setMovieDetails(detailsRes.data);
        setCredits(creditsRes.data);
        setRecommendations(recommendationsRes.data.results);
        setTotalPages(recommendationsRes.data.total_pages);
        setReviews(reviewsRes.data.results.slice(0, 3));
      } catch (error) {
        console.error('Error fetching movie data:', error);
      }
    };

    fetchData();
    setCurrentPage(1);
  }, [movieId]);

  useEffect(() => {
    const container = recommendationsRef.current;
    if (container) {
      container.addEventListener('scroll', checkScrollButtons);
      return () => container.removeEventListener('scroll', checkScrollButtons);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    const container = castListRef.current;
    if (container) {
      container.addEventListener('scroll', checkCastScrollButtons);
      return () => container.removeEventListener('scroll', checkCastScrollButtons);
    }
  }, []);

  useEffect(() => {
    if (user && movieId) {
      const favoriteRef = ref(database, `users/${user.uid}/favoritemovie/${movieId}`);
      const unsubscribe = onValue(favoriteRef, (snapshot) => {
        setIsFavorite(snapshot.exists());
      });
      
      return () => unsubscribe();
    }
  }, [user, movieId]);

  const checkScrollButtons = () => {
    const container = recommendationsRef.current;
    if (container) {
      const isAtStart = container.scrollLeft === 0;
      const isAtEnd = container.scrollLeft >= (container.scrollWidth - container.clientWidth - 50);
      
      setCanScrollLeft(!isAtStart);
      setCanScrollRight(!isAtEnd || currentPage < totalPages);

      if (isAtEnd && !loading && currentPage < totalPages) {
        loadMoreRecommendations();
      }
    }
  };

  const handleScrollButton = (direction) => {
    const container = recommendationsRef.current;
    if (container && !isScrolling) {
      setIsScrolling(true);
      const currentScroll = container.scrollLeft;
      const scrollAmount = direction === 'left' ? -SCROLL_AMOUNT : SCROLL_AMOUNT;
      const targetScroll = currentScroll + scrollAmount;

      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
      
      setTimeout(() => {
        setIsScrolling(false);
        checkScrollButtons();
      }, 500);
    }
  };

  const checkCastScrollButtons = () => {
    const container = castListRef.current;
    if (container) {
      const isAtStart = container.scrollLeft === 0;
      const isAtEnd = container.scrollLeft >= (container.scrollWidth - container.clientWidth - 50);
      setCanScrollLeftCast(!isAtStart);
      setCanScrollRightCast(!isAtEnd);
    }
  };

  const handleCastScrollButton = (direction) => {
    const container = castListRef.current;
    if (container && !isScrollingCast) {
      setIsScrollingCast(true);
      const currentScroll = container.scrollLeft;
      const scrollAmount = direction === 'left' ? -SCROLL_AMOUNT : SCROLL_AMOUNT;
      const targetScroll = currentScroll + scrollAmount;

      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
      
      setTimeout(() => {
        setIsScrollingCast(false);
        checkCastScrollButtons();
      }, 500);
    }
  };

  const loadMoreRecommendations = async () => {
    const nextPage = currentPage + 1;
    try {
      setLoading(true);
      const response = await getMovieRecommendations(movieId, nextPage);
      setRecommendations(prevMovies => [...prevMovies, ...response.data.results]);
      setCurrentPage(nextPage);
      setTotalPages(response.data.total_pages);
      console.log("totalPages", totalPages);
      console.log("currentPage", currentPage);
      setLoading(false);
    } catch (error) {
      console.error('Error loading more recommendations:', error);
      setLoading(false);
    }
  };

  const handleToggleFavorite = async () => {
    if (!user) {
      alert('Vui lòng đăng nhập để lưu phim yêu thích');
      return;
    }

    const favoriteRef = ref(database, `users/${user.uid}/favoritemovie/${movieId}`);
    
    try {
      if (isFavorite) {
        await remove(favoriteRef);
        setIsFavorite(false);
      } else {
        await set(favoriteRef, {
          movieId: movieId,
          title: movieDetails?.title,
          posterPath: movieDetails?.poster_path,
          addedAt: new Date().toISOString()
        });
        setIsFavorite(true);
      }
    } catch (error) {
      console.log('Lỗi khi cập nhật phim yêu thích:', error);
      alert('Có lỗi xảy ra, vui lòng thử lại sau');
    }
  };

  return (
    <div className="movie-detail-container">
      <div className="movie-header">
        {movieDetails ? (
          <>
            <h1 className="movie-title">{movieDetails.title}</h1>
            <button 
              className={`favorite-btn ${isFavorite ? 'active' : ''}`}
              onClick={handleToggleFavorite}
            >
              <i className={`fas fa-heart ${isFavorite ? 'active' : ''}`}></i>
            </button>
          </>
        ) : (
          <div className="loading-state">Đang tải...</div>
        )}
      </div>

      <div className="trailer-container">
        {trailer ? (
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${trailer.key}`}
            style={{ border: 'none' }}
            allowFullScreen
            title="Movie Trailer"
          />
        ) : (
          <div className="no-trailer">Không tìm thấy trailer</div>
        )}
      </div>

      <div className="movie-info">
        <h2>Tổng Quan</h2>
        {movieDetails ? (
          <>
            <div className="movie-info-grid">
              <div className="info-item">
                <div className="info-label">Ngày phát hành</div>
                <div className="info-value">
                  {new Date(movieDetails.release_date).toLocaleDateString('vi-VN')}
                </div>
              </div>
              <div className="info-item">
                <div className="info-label">Thời lượng</div>
                <div className="info-value">{movieDetails.runtime} phút</div>
              </div>
              <div className="info-item">
                <div className="info-label">Đánh giá</div>
                <div className="info-value">
                  {movieDetails.vote_average.toFixed(1)}/10
                </div>
              </div>
              <div className="info-item">
                <div className="info-label">Thể loại</div>
                <div className="info-value">
                  {movieDetails.genres.map(genre => genre.name).join(', ')}
                </div>
              </div>
            </div>
            <p className="overview">{movieDetails.overview}</p>
          </>
        ) : (
          <div className="loading-state">Đang tải thông tin phim...</div>
        )}

        <h2>Diễn Viên</h2>
        {credits ? (
          <div className="cast-row">
            <div className="cast-row-container">
              {canScrollLeftCast && (
                <button
                  className="nav-button left"
                  onClick={() => handleCastScrollButton('left')}
                >
                  <i className="fas fa-chevron-left"></i>
                </button>
              )}

              <div 
                className="cast-list"
                ref={castListRef}
              >
                {credits.cast.map((actor) => (
                  <div 
                    key={actor.id} 
                    className="cast-item"
                    onClick={() => navigate(`/actor/${actor.id}`)}
                    style={{ cursor: 'pointer' }}
                  >
                    <img 
                      src={`https://image.tmdb.org/t/p/w200${actor.profile_path}`}
                      alt={actor.name}
                      onError={(e) => e.target.src = 'https://i.pinimg.com/564x/a4/3e/e6/a43ee6d3e310564af22b71bdfb1a52e7.jpg'}
                    />
                    <p>{actor.name}</p>
                    <p className="character">{actor.character}</p>
                  </div>
                ))}
              </div>

              {canScrollRightCast && (
                <button
                  className="nav-button right"
                  onClick={() => handleCastScrollButton('right')}
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              )}
            </div>
          </div>
        ) : (
          <div>Không tìm thấy diễn viên</div>
        )}

        <h2>Đánh Giá Từ Người Xem</h2>
        {reviews ? (
          <div className="reviews-container">
            {reviews.length > 0 ? (
              reviews.map((review) => (
                <div key={review.id} className="review-item">
                  <div className="review-header">
                    <img 
                      src={review.author_details.avatar_path 
                        ? `https://image.tmdb.org/t/p/w45${review.author_details.avatar_path}`
                        : 'https://secure.gravatar.com/avatar/default?s=45'
                      }
                      alt={review.author}
                      className="reviewer-avatar"
                      onError={(e) => {
                        e.target.src = 'https://secure.gravatar.com/avatar/default?s=45';
                      }}
                    />
                    <div>
                      <h4>{review.author}</h4>
                      <span className="review-date">
                        {new Date(review.created_at).toLocaleDateString('vi-VN', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </span>
                    </div>
                  </div>
                  <p className="review-content">
                    {review.content.length > 300 
                      ? `${review.content.slice(0, 300)}...` 
                      : review.content
                    }
                  </p>
                  {review.content.length > 300 && (
                    <a 
                      href={review.url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="read-more"
                    >
                      Đọc thêm <i className="fas fa-external-link-alt"></i>
                    </a>
                  )}
                </div>
              ))
            ) : (
              <div className="no-reviews">
                <i className="far fa-comment-dots"></i>
                <p>Chưa có đánh giá nào cho phim này.</p>
              </div>
            )}
          </div>
        ) : (
          <div className="no-reviews">Đang tải đánh giá...</div>
        )}

        <h2>Phim Đề Xuất</h2>
        <div className="movie-row">
          <div className="movie-row-container">
            {canScrollLeft && (
              <button
                className="nav-button left"
                onClick={() => handleScrollButton('left')}
              >
                <i className="fas fa-chevron-left"></i>
              </button>
            )}

            <div
              className="movie-row-posters"
              ref={recommendationsRef}
            >
              {recommendations.map((movie) => (
                <div 
                  key={movie.id} 
                  className="movie-poster"
                  onClick={() => navigate(`/movie/${movie.id}`)}
                >
                  <img
                    src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                    alt={movie.title}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = 'https://i.pinimg.com/564x/a4/3e/e6/a43ee6d3e310564af22b71bdfb1a52e7.jpg';
                    }}
                  />
                  <div className="movie-title">{movie.title}</div>
                </div>
              ))}
              {loading && (
                <div className="loading-container">
                  <div className="loading-spinner"></div>
                </div>
              )}
            </div>

            {(canScrollRight || loading) && (
              <button
                className="nav-button right"
                onClick={() => handleScrollButton('right')}
              >
                <i className="fas fa-chevron-right"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MovieTrailer;