import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMoviesByGenre } from '../api/tmdb';

const MovieRow = ({ title, genreId }) => {
  const navigate = useNavigate();
  const [movies, setMovies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const scrollContainerRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  const IMG_BASE_URL = 'https://image.tmdb.org/t/p/w500';
  const SCROLL_AMOUNT = 1000;

  const checkScrollButtons = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const isAtStart = container.scrollLeft === 0;
      const isAtEnd = container.scrollLeft >= (container.scrollWidth - container.clientWidth - 50);

      setCanScrollLeft(!isAtStart);
      setCanScrollRight(!isAtEnd || currentPage < totalPages);

      // Tự động load thêm khi gần đến cuối
      if (isAtEnd && !loading && currentPage < totalPages) {
        loadNextPage();
      }
    }
  };

  const loadNextPage = async () => {
    const nextPage = currentPage + 1;
    console.log("nextPage",nextPage);
    try {
      setLoading(true);
      const response = await getMoviesByGenre(genreId, nextPage);
      setMovies(prevMovies => [...prevMovies, ...response.data.results]);
      setCurrentPage(nextPage);
      setLoading(false);
    } catch (error) {
      console.error(`Error loading next page for genre ${genreId}:`, error);
      setLoading(false);
    }
  };

  const handleScrollButton = (direction) => {
    const container = scrollContainerRef.current;
    if (container && !isScrolling) {
      setIsScrolling(true);
      const currentScroll = container.scrollLeft;
      const scrollAmount = direction === 'left' ? -SCROLL_AMOUNT : SCROLL_AMOUNT;
      const targetScroll = currentScroll + scrollAmount;

      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
      
      setTimeout(() => {
        setIsScrolling(false);
        const isNearEnd = container.scrollLeft >= (container.scrollWidth - container.clientWidth - 200);
        checkScrollButtons();
      }, 300); // Đợi 500ms cho animation scroll hoàn thành
    }
  };

  const handleMovieClick = (movieId) => {
    navigate(`/movie/${movieId}`);
  };

  const handleGenreClick = (genreId) => {
    navigate(`/genres/${genreId}`);
  };

  useEffect(() => {
    const fetchInitialMovies = async () => {
      try {
        setLoading(true);
        const response = await getMoviesByGenre(genreId, 1);
        setMovies(response.data.results);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      } catch (error) {
        console.error(`Error fetching initial movies for genre ${genreId}:`, error);
        setLoading(false);
      }
    };

    fetchInitialMovies();
  }, [genreId]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkScrollButtons);
      return () => container.removeEventListener('scroll', checkScrollButtons);
    }
  }, [currentPage, totalPages]);

  return (
    <div className="movie-row">
      <h2 className="category-title" onClick={() => handleGenreClick(genreId)}>{title}</h2>
      <div className="movie-row-container">
        {canScrollLeft && (
          <button
            className="nav-button left"
            onClick={() => handleScrollButton('left')}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
        )}

        <div
          className="movie-row-posters"
          ref={scrollContainerRef}
        >
          {movies.map((movie) => (
            <div 
              key={movie.id} 
              className="movie-poster"
              onClick={() => handleMovieClick(movie.id)}
            >
              <img
                src={`${IMG_BASE_URL}${movie.poster_path}`}
                alt={movie.title}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'https://via.placeholder.com/500x750?text=No+Image';
                }}
              />
              <div className="movie-title">{movie.title}</div>
            </div>
          ))}
          {loading && (
            <div className="loading-container">
              <div className="loading-spinner"></div>
            </div>
          )}
        </div>

        {(canScrollRight || loading) && (
          <button
            className="nav-button right"
            onClick={() => handleScrollButton('right')}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default MovieRow;