import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Register.css';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (password !== confirmPassword) {
      toast.error('Mật khẩu không khớp');
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      toast.success('Đăng ký thành công!');
      navigate('/');
    } catch (err) {
      switch (err.code) {
        case 'auth/email-already-in-use':
          toast.error('Email đã được sử dụng');
          break;
        case 'auth/weak-password':
          toast.error('Mật khẩu phải có ít nhất 6 ký tự');
          break;
        default:
          toast.error('Đã có lỗi xảy ra khi đăng ký');
      }
    }
  };

  return (
    <div className="register-container">
      <div className="register-form">
        <h2>Đăng ký tài khoản</h2>
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email của bạn"
              required
              autoComplete="off"
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Mật khẩu"
              required
              autoComplete="new-password"
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Xác nhận mật khẩu"
              required
              autoComplete="new-password"
            />
          </div>

          <button type="submit">Đăng ký</button>
        </form>
      </div>
    </div>
  );
};

export default Register;
