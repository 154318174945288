import React from 'react';
import { useNavigate } from 'react-router-dom';
import './FavoriteMovies.css';

function SearchResult({ movies }) {
    const navigate = useNavigate();
    const handleMovieClick = (movieId) => {
        navigate(`/movie/${movieId}`);
    };
    const IMG_BASE_URL = 'https://image.tmdb.org/t/p/w500';
    return (
        <div className="search-results">
            <h2>Kết quả tìm kiếm</h2>
            <div
                className="list-movies-favorite"
            >
                {movies.map((movie) => (
                    <div
                        key={movie.id}
                        className="movie-poster-primary"
                        onClick={() => handleMovieClick(movie.id)}
                    >
                        <img
                            src={`${IMG_BASE_URL}${movie.poster_path}`}
                            alt={movie.title}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = 'https://via.placeholder.com/500x750?text=No+Image';
                            }}
                        />
                        <div className="movie-title">{movie.title}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}



export default SearchResult;
