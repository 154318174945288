import { useState, useRef, useEffect } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase/config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import React, { ChangeEvent, SyntheticEvent } from 'react';
import {
  useRive,
  useStateMachineInput,
  Layout,
  Fit,
  Alignment,
  UseRiveParameters,
  RiveState,
  StateMachineInput,
} from 'rive-react';
import './Login.css';
const SM_NAME = 'Login Machine';

const Login = (riveProps = {}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [textMultiplier, setTextMultiplier] = useState(1);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const { rive: riveInstance, RiveComponent } = useRive({
    src: 'login-teddy.riv',
    stateMachines: SM_NAME,
    autoplay: true,
    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.Center,
    }),
    ...riveProps,
  });

  const isCheckingInput = useStateMachineInput(
    riveInstance,
    SM_NAME,
    'isChecking'
  );
  const numLookInput = useStateMachineInput(
    riveInstance,
    SM_NAME,
    'numLook'
  );
  const trigSuccessInput = useStateMachineInput(
    riveInstance,
    SM_NAME,
    'trigSuccess'
  );
  const trigFailInput = useStateMachineInput(
    riveInstance,
    SM_NAME,
    'trigFail'
  );
  const isHandsUpInput = useStateMachineInput(
    riveInstance,
    SM_NAME,
    'isHandsUp'
  );

  const onUsernameChange = (e) => {
    setEmail(e.target.value);
    if (!isCheckingInput.value) {
      isCheckingInput.value = true;
    }
    const numChars = e.target.value.length;
    numLookInput.value = numChars * 2;
  };

  const onUsernameFocus = () => {
    isCheckingInput.value = true;
    if (numLookInput.value !== email.length * 2) {
      numLookInput.value = email.length * 2;
    }
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      if (trigSuccessInput) {
        trigSuccessInput.fire();
      }
      navigate('/');
    } catch (error) {
      if (trigFailInput) {
        trigFailInput.fire();
      }
      console.log("error",error.message);
      console.log("error",error.code);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      if (trigSuccessInput) {
        trigSuccessInput.fire();
      }
      toast.success('Đăng nhập thành công!');
      navigate('/');
    } catch (error) {
      if (trigFailInput) {
        trigFailInput.fire();
      }
      toast.error(error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="rive-wrapper">
        <RiveComponent className="login-animation" src="/login_screen_character.riv" />
      </div>
      
      <form onSubmit={handleLogin} className="login-form">
        <h2>Đăng nhập</h2>
        <div className="form-group">
          <input
            ref={inputRef}
            type="email"
            value={email}
            onChange={(e) => 
              onUsernameChange(e)
            }
            onFocus={onUsernameFocus}
            onBlur={() => isCheckingInput && (isCheckingInput.value = false)}
            placeholder="Email của bạn"
          />
        </div>

        <div className="form-group">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={() => isHandsUpInput && (isHandsUpInput.value = true)}
            onBlur={() => isHandsUpInput && (isHandsUpInput.value = false)}
            placeholder="Mật khẩu"
          />
        </div>

        <button type="submit" disabled={isLoading}>
          {isLoading ? (
            <span className="loading-spinner"></span>
          ) : (
            'Đăng nhập'
          )}
        </button>

        <button type="button" onClick={handleGoogleLogin} className="google-btn">
          <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google" />
          Đăng nhập với Google
        </button>
      </form>
    </div>
  );
};

export default Login; 