import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './FavoriteMovies.css';
import { getMoviesByGenre } from '../api/tmdb';

const MovieGenres = () => {
    const navigate = useNavigate();
    const { genreId } = useParams();
    const handleMovieClick = (movieId) => {
        navigate(`/movie/${movieId}`);
    };
    const IMG_BASE_URL = 'https://image.tmdb.org/t/p/w500';
    const [movies, setSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const loadMovies = useCallback(async (page = 1) => {
        try {
            console.log("page", page);
            console.log("genreId", genreId);
            const response = await getMoviesByGenre(genreId, page);
            console.log("response", response);
            const data = await response.data;
            setTotalPages(data.total_pages);
            console.log("totalPages", data.total_pages);

            if (page === 1) {
                setSearchResults(data.results);
            } else {
                setSearchResults((prevResults) => [...prevResults, ...data.results]);
            }
        } catch (error) {
            console.error('Lỗi khi tìm kiếm phim:', error);
        }
    }, [genreId]);
    useEffect(() => {
        loadMovies();
    }, [genreId]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 10) {
                if (currentPage < totalPages) {
                    setCurrentPage((prevPage) => {
                        const nextPage = prevPage + 1;
                        loadMovies(nextPage);
                        return nextPage;
                    });
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [currentPage, totalPages, loadMovies]);


    return (
        <div className="search-results">
            <h2>Kết quả tìm kiếm</h2>
            <div
                className="list-movies-favorite"
            >
                {movies.map((movie) => (
                    <div
                        key={movie.id}
                        className="movie-poster-primary"
                        onClick={() => handleMovieClick(movie.id)}
                    >
                        <img
                            src={`${IMG_BASE_URL}${movie.poster_path}`}
                            alt={movie.title}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = 'https://via.placeholder.com/500x750?text=No+Image';
                            }}
                        />
                        <div className="movie-title">{movie.title}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MovieGenres;
