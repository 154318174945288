import { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { auth, database } from '../firebase/config';
import { useNavigate } from 'react-router-dom';
import './FavoriteMovies.css';

const FavoriteMovies = () => {
  const [favorites, setFavorites] = useState([]);
  const [user] = useState(auth.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    const favoritesRef = ref(database, `users/${user.uid}/favoritemovie`);
    
    const unsubscribe = onValue(favoritesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Chuyển đổi object thành array
        const favoritesArray = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value
        }));
        setFavorites(favoritesArray);
      } else {
        setFavorites([]);
      }
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, [user]);

  return (
    <div className="row">
      <h2 className="text-white">Phim Yêu Thích</h2>
      <div className="list-movies-favorite">
        {favorites.length === 0 ? (
          <p className="text-white">Bạn chưa có phim yêu thích nào.</p>
        ) : (
          favorites.map((movie) => (
            <div 
              key={movie.id} 
              className="movie-poster-primary"
              onClick={() => navigate(`/movie/${movie.id}`)}
            >
              <img
                src={`https://image.tmdb.org/t/p/original${movie.poster_path}`}
                alt={movie.title}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'https://via.placeholder.com/500x750?text=No+Image';
                }}
              />
              <div className="movie-title">{movie.title}</div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default FavoriteMovies; 