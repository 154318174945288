import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCOEE7ev6GplsHhV8MP7AIDHnRxqQBLkJo",
    authDomain: "movie-trailers-7df7c.firebaseapp.com",
    databaseURL: "https://movie-trailers-7df7c-default-rtdb.firebaseio.com",
    projectId: "movie-trailers-7df7c",
    storageBucket: "movie-trailers-7df7c.appspot.com",
    messagingSenderId: "245107555689",
    appId: "1:245107555689:web:d77284a504aa67c68011f8",
    measurementId: "G-0RRXMBSYCC"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);