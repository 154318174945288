import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getActorDetails, getActorMovies } from '../api/tmdb';
import { auth, database } from '../firebase/config';
import { ref, set, remove, onValue } from 'firebase/database';

function ActorDetail() {
  const [actorDetails, setActorDetails] = useState(null);
  const [actorMovies, setActorMovies] = useState(null);
  const { actorId } = useParams();
  const navigate = useNavigate();
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const moviesListRef = useRef(null);
  const SCROLL_AMOUNT = 1000;
  const [user] = useState(auth.currentUser);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const fetchData = async () => {
      try {
        const [detailsRes, moviesRes] = await Promise.all([
          getActorDetails(actorId),
          getActorMovies(actorId)
        ]);

        setActorDetails(detailsRes.data);
        const sortedMovies = moviesRes.data.cast.sort((a, b) => b.popularity - a.popularity);
        setActorMovies(sortedMovies);
      } catch (error) {
        console.error('Error fetching actor data:', error);
      }
    };

    fetchData();
  }, [actorId]);

  useEffect(() => {
    const container = moviesListRef.current;
    if (container) {
      container.addEventListener('scroll', checkScrollButtons);
      return () => container.removeEventListener('scroll', checkScrollButtons);
    }
  }, []);

  useEffect(() => {
    if (user && actorId) {
      const favoriteRef = ref(database, `users/${user.uid}/favoritecast/${actorId}`);
      const unsubscribe = onValue(favoriteRef, (snapshot) => {
        setIsFavorite(snapshot.exists());
      });
      
      return () => unsubscribe();
    }
  }, [user, actorId]);

  const checkScrollButtons = () => {
    const container = moviesListRef.current;
    if (container) {
      const isAtStart = container.scrollLeft === 0;
      const isAtEnd = container.scrollLeft >= (container.scrollWidth - container.clientWidth - 50);
      setCanScrollLeft(!isAtStart);
      setCanScrollRight(!isAtEnd);
    }
  };

  const handleScrollButton = (direction) => {
    const container = moviesListRef.current;
    if (container && !isScrolling) {
      setIsScrolling(true);
      const currentScroll = container.scrollLeft;
      const scrollAmount = direction === 'left' ? -SCROLL_AMOUNT : SCROLL_AMOUNT;
      const targetScroll = currentScroll + scrollAmount;

      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
      
      setTimeout(() => {
        setIsScrolling(false);
        checkScrollButtons();
      }, 500);
    }
  };

  const handleToggleFavorite = async () => {
    if (!user) {
      alert('Vui lòng đăng nhập để thêm vào danh sách yêu thích!');
      return;
    }

    const favoriteRef = ref(database, `users/${user.uid}/favoritecast/${actorId}`);
    
    try {
      if (isFavorite) {
        await remove(favoriteRef);
      } else {
        await set(favoriteRef, {
          id: actorId,
          name: actorDetails.name,
          profile_path: actorDetails.profile_path,
          known_for_department: actorDetails.known_for_department,
          timestamp: Date.now()
        });
      }
    } catch (error) {
      console.error('Lỗi khi cập nhật trạng thái yêu thích:', error);
    }
  };

  return (
    <div className="movie-detail-container">
      {actorDetails ? (
        <>
          <div className="actor-header">
            <div className="actor-image">
              <img
                src={`https://image.tmdb.org/t/p/w500${actorDetails.profile_path}`}
                alt={actorDetails.name}
                onError={(e) => e.target.src = 'https://i.pinimg.com/564x/a4/3e/e6/a43ee6d3e310564af22b71bdfb1a52e7.jpg'}
              />
              <button 
                className={`favorite-button ${isFavorite ? 'active' : ''}`}
                onClick={handleToggleFavorite}
              >
                <i className={`fas ${isFavorite ? 'fa-heart' : 'fa-heart-o'}`}></i>
              </button>
            </div>
            <div className="actor-info-container">
              <h1 className="actor-name">{actorDetails.name}</h1>
              <div className="actor-info-grid">
                <div className="info-item">
                  <div className="info-label">Ngày sinh</div>
                  <div className="info-value">
                    {new Date(actorDetails.birthday).toLocaleDateString('vi-VN')}
                  </div>
                </div>
                {actorDetails.deathday && (
                  <div className="info-item">
                    <div className="info-label">Ngày mất</div>
                    <div className="info-value">
                      {new Date(actorDetails.deathday).toLocaleDateString('vi-VN')}
                    </div>
                  </div>
                )}
                <div className="info-item">
                  <div className="info-label">Nơi sinh</div>
                  <div className="info-value">{actorDetails.place_of_birth}</div>
                </div>
                <div className="info-item">
                  <div className="info-label">Nghề nghiệp</div>
                  <div className="info-value">{actorDetails.known_for_department}</div>
                </div>
              </div>
              <div className="biography">
                <h2>Tiểu sử</h2>
                <p className="overview">{actorDetails.biography || 'Không có thông tin.'}</p>
              </div>
            </div>
          </div>

          <div className="filmography">
            <h2>Phim đã tham gia</h2>
            <div className="movie-row">
              <div className="movie-row-container">
                {canScrollLeft && (
                  <button
                    className="nav-button left"
                    onClick={() => handleScrollButton('left')}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </button>
                )}

                <div
                  className="movie-row-posters"
                  ref={moviesListRef}
                >
                  {actorMovies?.map((movie) => (
                    <div 
                      key={movie.id} 
                      className="movie-poster"
                      onClick={() => navigate(`/movie/${movie.id}`)}
                    >
                      <img
                        src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                        alt={movie.title}
                        onError={(e) => {
                          e.target.src = 'https://i.pinimg.com/564x/a4/3e/e6/a43ee6d3e310564af22b71bdfb1a52e7.jpg';
                        }}
                      />
                      
                    </div>
                  ))}
                </div>

                {canScrollRight && (
                  <button
                    className="nav-button right"
                    onClick={() => handleScrollButton('right')}
                  >
                    <i className="fas fa-chevron-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="loading-state">Đang tải...</div>
      )}
    </div>
  );
}

export default ActorDetail; 