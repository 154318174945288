import React, { useState, useEffect } from 'react';
import { auth, database } from '../firebase/config';
import { ref, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import './FavoriteActors.css';

const FavoriteActors = () => {
  const [favoriteActors, setFavoriteActors] = useState([]);
  const [user] = useState(auth.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const favoriteActorsRef = ref(database, `users/${user.uid}/favoritecast`);
      const unsubscribe = onValue(favoriteActorsRef, (snapshot) => {
        if (snapshot.exists()) {
          // Chuyển đổi dữ liệu từ Firebase thành mảng
          const actorsData = snapshot.val();
          const actorsArray = Object.values(actorsData).sort((a, b) => b.timestamp - a.timestamp);
          setFavoriteActors(actorsArray);
        } else {
          setFavoriteActors([]);
        }
      });

      return () => unsubscribe();
    }
  }, [user]);

  return (
    <div className="row">
      <h2 className="text-white">Diễn Viên Yêu Thích</h2>
      <div className="list-actors-favorite">
        {favoriteActors.length === 0 ? (
          <p className="text-white">Bạn chưa có diễn viên yêu thích nào.</p>
        ) : (
          favoriteActors.map((actor) => (
            <div key={actor.id} className="cast-item">
              <img
                onClick={() => navigate(`/actor/${actor.id}`)}
                className="actor-image"
                src={`https://image.tmdb.org/t/p/w500${actor.profile_path}`}
                alt={actor.name}
                onError={(e) => {
                  e.target.src = 'https://i.pinimg.com/564x/a4/3e/e6/a43ee6d3e310564af22b71bdfb1a52e7.jpg';
                }}
              />
              <div className="actor-info">
                <h3>{actor.name}</h3>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default FavoriteActors; 