import axios from 'axios';

const API_KEY = '9ecf1d7370506c5ed53c9c28ec8bb100';
const BASE_URL = 'https://api.themoviedb.org/3';

export const tmdbApi = axios.create({
  baseURL: BASE_URL,
  params: {
    api_key: API_KEY,
  },
});

export const getPopularMovies = () => {
  return tmdbApi.get('/movie/popular');
};

export const getMovieTrailer = (movieId) => {
  return tmdbApi.get(`/movie/${movieId}/videos`);
};

export const getMoviesByGenre = (genreId, page = 1) => {
  return tmdbApi.get('/discover/movie', {
    params: {
      with_genres: genreId,
      sort_by: 'popularity.desc',
      page: page,
      include_adult: false,
    },
  });
};

export const getMovieDetails = (movieId) => {
  return axios.get(`${BASE_URL}/movie/${movieId}?api_key=${API_KEY}`);
};

export const getMovieCredits = (movieId) => {
  return axios.get(`${BASE_URL}/movie/${movieId}/credits?api_key=${API_KEY}`);
};

export const getMovieRecommendations = (movieId, page = 1) => {
  return axios.get(`${BASE_URL}/movie/${movieId}/recommendations?api_key=${API_KEY}&page=${page}`);
};

export const getMovieReviews = (movieId) => {
  return axios.get(`${BASE_URL}/movie/${movieId}/reviews?api_key=${API_KEY}`);
};

export const getActorDetails = (actorId) => {
  return axios.get(`${BASE_URL}/person/${actorId}?api_key=${API_KEY}`);
};

export const getActorMovies = (actorId) => {
  return axios.get(`${BASE_URL}/person/${actorId}/movie_credits?api_key=${API_KEY}`);
};

export const searchMovies = (query, page = 1) => {
  return axios.get(`${BASE_URL}/search/movie`, {
    params: {
      api_key: API_KEY,
      query: query,
      page: page,
      language: 'vi-VN'
    }
  });
};